import axios from "axios";
import apiConfig from "./api.config";

// Step-1: Create a new Axios instance with a custom config.
// The timeout is set to 10s. If the request takes longer than
// that then the request will be aborted.
const customAxios = axios.create({
    ...apiConfig,
    timeout: 30000,
    withCredentials: false,
    // custom headers can be added here as shown below
    // headers: { 'api-key': 'eyJz-CI6Ikp-4pWY-lhdCI6' }
});

// Step-2: Create request, response & error handlers
const requestHandler = (request) => {
    request.headers.Accept = "application/json";
    request.headers["Content-Type"] = "application/json";
    request.headers["Access-Control-Allow-Origin"] = "true";

    return request;
};

const responseHandler = (response) => response;

const errorHandler = (error) => Promise.reject(error);

// Step-3: Configure/make use of request & response interceptors from Axios
// Note: You can create one method say configureInterceptors, add below in that,
// export and call it in an init function of the application/page.
customAxios.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

// Step-4: Export the newly created Axios instance to be used in different locations.
export default customAxios;
