import { Alert } from "@mui/material";
import PropTypes from "prop-types";
import * as styles from "./Toast.styles";

export const Toast = ({
  message = "",
  type = "success",
  toggle = false,
  icon,
  onClose,
  ...rest
}) => {
  const colors = {
    error: "#c0392b",
    success: "#27ae60",
    info: "#00c3f9",
  };
  return (
    <Alert
      severity={type}
      onClose={onClose}
      icon={icon}
      variant='filled'
      style={{ background: colors[type], color: "#fff" }}
      sx={styles.toastStyles(toggle)}
      {...rest}>
      {message}
    </Alert>
  );
};

Toast.propTypes = {
  /**
   * The content to be displayed within the toast
   */
  message: PropTypes.oneOfType(PropTypes.string, PropTypes.node).isRequired,
  /**
   * A boolean value to determine whether the alert is displayed
   * or hidden
   */
  toggle: PropTypes.bool.isRequired,
  /**
   * Determines the value that can be passed as a type
   */
  type: PropTypes.oneOf(["success", "info", "warning", "error"]).isRequired,
  /**
   * Override the icon displayed before the children.
   * Unless provided, the icon is mapped to the value of the `type` prop.
   * Set to `false` to remove the `icon`.
   */
  icon: PropTypes.node,
  /**
   * Callback fired when the component requests to be closed.
   * When provided and no `action` prop is set, a close icon button is displayed that
   * triggers the callback when clicked.
   */
  onClose: PropTypes.func,
};
