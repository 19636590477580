import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React from "react";
import * as Styles from "./footer.styles";

const JoinUs = () => {
    const { t } = useTranslation();

    return (
        <Box sx={Styles.joinUsWrapper}>
            <Container sx={Styles.joinUsContainer}>
                <Grid
                    container
                    spacing={2}
                >
                    <Grid
                        item
                        md={10}
                        xs={12}
                        sx={Styles.joinUsTitle}
                    >
                        <Typography
                            sx={{
                                color: "white",
                                fontSize: "25px",
                            }}
                        >
                            {t("footer.joinUs")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={2}
                        xs={12}
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: { xs: "center", lg: "end" },
                        }}
                    >
                        <Link
                            to="/contactUs"
                            style={{ textDecoration: "none" }}
                        >
                            <Button sx={Styles.joinUsBtn}>{t("footer.joinUsBtn")}</Button>
                        </Link>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default JoinUs;
