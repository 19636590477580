import AxiosInstance from "configs/api.service";
import { get, remove } from "configs/localstorage.service";

async function login(form) {
    return new Promise((resolve, reject) => {
        AxiosInstance.post("/api/auth/login", form)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });
}

async function logout() {
    remove("user");
    return new Promise((resolve, reject) => {
        AxiosInstance.post("/api/auth/logout")
            .then(() => {
                remove("user");
            })
            .catch((err) => reject(err));
    });
}
async function checkTokenExpiration() {
    const expiresIn = JSON.parse(get("user"))?.expiresIn;
    if (expiresIn && new Date(expiresIn).getTime() < new Date().getTime()) logout();
}

async function registr(form) {
    return new Promise((resolve, reject) => {
        AxiosInstance.post("/api/auth/register", form)
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
}

async function getProfile(token) {
    return new Promise((resolve, reject) => {
        AxiosInstance.get("/api/me", {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

async function updateProfile(token, data) {
    return new Promise((resolve, reject) => {
        AxiosInstance.post("/api/me?_method=patch", data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

async function updatePassword(token, data) {
    return new Promise((resolve, reject) => {
        AxiosInstance.post(
            "/api/password/update?_method=patch",
            { password: data },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}

function userConfirmation(token) {
    return new Promise((resolve, reject) => {
        AxiosInstance.post(`/api/email/verify/${token}`, {})
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
}
const userResetPassword = (data) =>
    new Promise((resolve, reject) => {
        AxiosInstance.post("/api/password/email", data)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
const resetPassowrd = (data) =>
    new Promise((resolve, reject) => {
        AxiosInstance.post("/api/password/reset", data)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
const getStaticContent = () =>
    new Promise((resolve, reject) => {
        AxiosInstance.get("/api/contents/groups?all=true")
            .then((res) => resolve(res))
            .catch((err) => reject(err));
    });
export {
    login,
    registr,
    logout,
    getProfile,
    updateProfile,
    updatePassword,
    checkTokenExpiration,
    userConfirmation,
    userResetPassword,
    resetPassowrd,
    getStaticContent,
};
