export const wraper = {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
};
export const cardsWraper = {
    display: "flex",
    // alignItems: "center",
    marginBottom: "40px",
};
export const imgWraper = {
    width: "300px",
    height: "100px",
    overflow: "hidden",
    borderRadius: "15px",
};
export const img = {
    width: "100%",
    borderRadius: "15px",
    height: "100%",
    objectFit: "cover",
};
export const content = {
    display: "flex",
    flexDirection: "column",
    marginInline: "8px",
};
export const header = (textAlign, titleColor) => ({
    color: titleColor || "rgba(40, 41, 61, 1)",
    textAlign,
});
export const date = (textAlign, dateColor) => ({
    fontSize: "14px",
    color: dateColor || "rgba(219, 96, 21, 1)",
    textAlign,
});
