/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from "react";
import { Avatar, Badge, Box, Button, Grid, Typography } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ColoredLogo from "assets/images/coloredLogo.png";
import WhiteLogo from "assets/icons/navbarLogo.png";
import ShoppingCart from "assets/icons/cart.svg";
import { get, set } from "configs/localstorage.service";
import CustomDropDown from "components/shared/uikit/dropdown/dropDown";
import { activeNavLink, cartItems, navbarOpened } from "recoil/atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import { DonationsMenu, NewsAndEventsMenu, ProfileMenu, WhoAreWeMenu } from "helpers/constants";
import {
    BurgerMenu,
    ButtonStyles,
    DropDown,
    LanguageNav,
    NavItems,
    Navbars,
    activeLink,
} from "./navbar.styles";

import ResponsiveNavbar from "./responsiveNavbar";

const Navbar = () => {
    const [opened, setOpened] = useRecoilState(navbarOpened);
    const [active, setActive] = useRecoilState(activeNavLink);
    const cartCount = useRecoilValue(cartItems);
    const { t, i18n } = useTranslation();
    const [coloring, setColoring] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const user = JSON.parse(get("user"));
    useEffect(() => {
        document.documentElement.lang = i18n.language;
    }, [i18n.language]);
    const black =
        location?.pathname.includes("login") ||
        location?.pathname.includes("registration") ||
        location?.pathname.includes("cart") ||
        location?.pathname.includes("404") ||
        location?.pathname.includes("profile") ||
        location?.pathname.includes("emailConfirmation") ||
        location?.pathname.includes("resetPasswordEmail") ||
        location?.pathname.includes("changePassword");
    return (
        <Box sx={Navbars(opened)}>
            <Grid container>
                <Grid
                    xs={1}
                    sx={{
                        userSelect: "none ",
                        borderRadius: 1,
                        width: 2,
                        height: 120,
                        display: "flex",
                        alignItems: "center",
                        zIndex: 999,
                        justifyContent: "start",
                    }}
                >
                    <Link to="/">
                        <Avatar
                            src={ColoredLogo}
                            sx={{
                                cursor: "pointer",
                                width: 104,
                                height: 108,
                                borderRadius: 1,
                                position: "absolute",
                                top: 10,
                                opacity: coloring && !black ? 0 : 1,
                                transition: "ease-in-out .3s",
                            }}
                            alt=""
                        />
                        <Avatar
                            src={ColoredLogo}
                            onMouseEnter={() => setColoring(true)}
                            onMouseLeave={() => setColoring(false)}
                            sx={{
                                cursor: "pointer",
                                width: 103,
                                height: 119,
                                borderRadius: 1,
                                position: "absolute",
                                top: 10,
                                opacity: coloring || black ? 1 : 0,
                                transition: "ease-in-out .3s",
                            }}
                            alt=""
                        />
                    </Link>
                </Grid>
                <Grid
                    xs={11}
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: { xs: "end", lg: "space-between" },
                    }}
                >
                    <Box
                        onClick={() => {
                            setOpened((prev) => !prev);
                        }}
                        sx={{
                            display: { xs: "blok", lg: "none" },
                            cursor: "pointer",
                            userSelect: "none",
                            zIndex: 999,
                        }}
                    >
                        <Box sx={BurgerMenu("rotate(45deg)  translateX(6.5px)", opened, black)} />
                        <Box sx={BurgerMenu(2, opened, black)} />
                        <Box sx={BurgerMenu("rotate(-45deg)  translateX(5px)", opened, black)} />
                    </Box>
                    <Box
                        sx={{
                            width: "100%",
                            display: { xs: "none", lg: "flex" },
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexDirection: { xs: "column", lg: "row" },
                        }}
                    >
                        <CustomDropDown
                            label={t("navbar.who_are_we")}
                            items={WhoAreWeMenu}
                            setActive={setActive}
                            blackNav={black}
                            active="1"
                            setOpened={setOpened}
                            current={active}
                            sx={DropDown(black)}
                            transform={{
                                horizontal: i18n.language === "ar" ? "right" : "left",
                                vertical: "top",
                            }}
                            anchor={{
                                horizontal: i18n.language === "ar" ? "right" : "left",
                                vertical: "bottom",
                            }}
                        />

                        <Link
                            style={{ textDecoration: "none" }}
                            to="/oursectors"
                        >
                            <Typography
                                variant="body1"
                                css={[NavItems(black), activeLink(active === "5")]}
                            >
                                {t("navbar.our_sectors")}
                            </Typography>
                        </Link>

                        <CustomDropDown
                            label={t("navbar.news_and_events")}
                            items={NewsAndEventsMenu}
                            setActive={setActive}
                            setOpened={setOpened}
                            blackNav={black}
                            active="3"
                            current={active}
                            sx={DropDown(black)}
                            transform={{
                                horizontal: i18n.language === "ar" ? "right" : "left",
                                vertical: "top",
                            }}
                            anchor={{
                                horizontal: i18n.language === "ar" ? "right" : "left",
                                vertical: "bottom",
                            }}
                        />
                        <Link
                            style={{ textDecoration: "none" }}
                            onClick={() => {
                                setActive("2");
                                set("active", "2");
                            }}
                            to="/research"
                        >
                            <Typography
                                variant="body1"
                                css={[NavItems(black), activeLink(active === "2")]}
                            >
                                {t("navbar.research_and_reports_hub")}
                            </Typography>
                        </Link>
                        <Link
                            onClick={() => {
                                setActive("4");
                                set("active", "4");
                            }}
                            to="/zkaty"
                            style={{ textDecoration: "none" }}
                        >
                            <Typography
                                variant="body1"
                                css={[NavItems(black), activeLink(active === "4")]}
                            >
                                {t("navbar.zakaty")}
                            </Typography>
                        </Link>
                        <Link
                            onClick={() => {
                                setActive("6");
                                set("active", "6");
                            }}
                            to="/joinUs"
                            style={{ textDecoration: "none" }}
                        >
                            <Typography
                                variant="body1"
                                css={[NavItems(black), activeLink(active === "8")]}
                            >
                                {t("navbar.joinUs")}
                            </Typography>
                        </Link>
                        <CustomDropDown
                            isParent
                            label={t("navbar.donations")}
                            items={DonationsMenu}
                            setOpened={setOpened}
                            setActive={setActive}
                            blackNav={black}
                            active="7"
                            current={active}
                            sx={DropDown(black)}
                            transform={{
                                horizontal: i18n.language === "ar" ? "right" : "left",
                                vertical: "top",
                            }}
                            anchor={{
                                horizontal: i18n.language === "ar" ? "right" : "left",
                                vertical: "bottom",
                            }}
                        />
                        <Badge
                            badgeContent={cartCount?.length}
                            color="warning"
                            onClick={() => navigate("/cart")}
                        >
                            <Avatar
                                src={ShoppingCart}
                                sx={{
                                    color: "#fff",
                                    borderRadius: black ? "50%" : 1,
                                    border: black && "2px solid #DB6015",
                                    width: 32,
                                    height: 32,
                                    cursor: "pointer",
                                }}
                            />
                        </Badge>
                        {user ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    width: 130,
                                    cursor: "pointer",
                                }}
                            >
                                <CustomDropDown
                                    label={
                                        <Avatar
                                            src={user?.avatar}
                                            sx={LanguageNav(false, black)}
                                        />
                                    }
                                    items={ProfileMenu}
                                    blackNav={black}
                                    setOpened={setOpened}
                                    current={active}
                                    sx={DropDown(black)}
                                    transform={{
                                        horizontal: "center",
                                        vertical: "top",
                                    }}
                                    anchor={{
                                        horizontal: "center",
                                        vertical: "bottom",
                                    }}
                                />
                            </Box>
                        ) : (
                            <React.Fragment>
                                <Button
                                    onClick={() => navigate("/login")}
                                    sx={ButtonStyles(true, false, black)}
                                >
                                    {t("navbar.login")}
                                </Button>
                                <Button
                                    onClick={() => navigate("/registration")}
                                    sx={ButtonStyles(false, false, black)}
                                >
                                    {t("navbar.register")}
                                </Button>
                            </React.Fragment>
                        )}
                        <Avatar
                            src={
                                i18n.language === "en"
                                    ? "https://flagcdn.com/w20/eg.png"
                                    : "https://flagcdn.com/w20/gb.png"
                            }
                            sx={LanguageNav(false, black)}
                            onClick={() => {
                                i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar").then(
                                    () => {
                                        set("lang", i18n.language);
                                    }
                                );
                            }}
                        />
                    </Box>
                </Grid>
            </Grid>
            <ResponsiveNavbar
                opened={opened}
                active={active}
                setOpened={setOpened}
                setActive={setActive}
                black
            />
        </Box>
    );
};

export default Navbar;
