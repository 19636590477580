import React, { useState } from "react";
import {
    Box,
    Button,
    Container,
    FormControl,
    FormHelperText,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { toast } from "components/shared/uikit/toast";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { sendMail } from "services/contactUs.service";
import * as Styles from "./footer.styles";

const ContactForm = () => {
    const { i18n, t } = useTranslation();
    const [submitted, setSubmitted] = useState(false);
    const contactForm = yup.object().shape({
        name: yup.string().required(t("validation.required")),
        email: yup.string().email(t("validation.email")),
        message: yup.string().min(4).required(t("validation.required")),
    });

    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({
        mode: "all",
        resolver: yupResolver(contactForm),
    });
    const handleSendingMail = (data) => {
        sendMail(data)
            .then(() => {
                setSubmitted(true);
                toast.success(t("contactUs.mailSent"));
            })
            .catch((err) => toast.error(err.response.data.message));
    };
    const alignment = i18n.dir() === "rtl" ? "right" : "left";

    return (
        <Box sx={Styles.joinUsWrapper}>
            <Container sx={Styles.joinUsContainer}>
                <Grid
                    container
                    spacing={20}
                >
                    <Grid
                        item
                        md={5}
                        xs={12}
                        sx={Styles.joinUsTitle}
                    >
                        <Typography
                            sx={{
                                color: "white",
                                fontSize: "45px",
                            }}
                        >
                            {t("footer.contactForm")}
                        </Typography>
                        <Typography
                            sx={{
                                color: "rgba(255, 255, 255, 0.9)",
                                fontSize: "18px",
                                marginBlock: "10px",
                                lineHeight: 1.8,
                            }}
                        >
                            {t("footer.fromdesc")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        md={7}
                        xs={12}
                        sx={{
                            display: "flex",
                            justifyContent: { xs: "center", lg: "end" },
                            alignItems: { xs: "center", lg: "end" },
                            flexDirection: "column",
                            width: "80%",
                        }}
                    >
                        <form onSubmit={handleSubmit(handleSendingMail)}>
                            <Grid
                                container
                                spacing={2}
                                justifyContent="center"
                            >
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <FormControl
                                        sx={{ width: "100%" }}
                                        error={errors?.name}
                                    >
                                        <TextField
                                            {...register("name")}
                                            type="text"
                                            sx={{
                                                backgroundColor: "white",
                                                border: "none",
                                                borderRadius: "5px",
                                            }}
                                            id="name"
                                            placeholder={t("footer.nameField")}
                                        />
                                        <FormHelperText
                                            sx={{
                                                color: "#fdd73b !important",
                                                textAlign: alignment,
                                            }}
                                        >
                                            {errors?.name?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={6}
                                >
                                    <FormControl
                                        sx={{ width: "100%" }}
                                        error={errors?.email}
                                    >
                                        <TextField
                                            {...register("email")}
                                            type="mail"
                                            sx={{
                                                backgroundColor: "white",
                                                border: "none",
                                                borderRadius: "5px",
                                            }}
                                            id="mail"
                                            placeholder={t("footer.mailField")}
                                        />
                                        <FormHelperText
                                            sx={{
                                                color: "#fdd73b !important",
                                                textAlign: alignment,
                                            }}
                                        >
                                            {errors?.email?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                >
                                    <FormControl
                                        sx={{ width: "100%" }}
                                        error={errors?.message}
                                    >
                                        <TextField
                                            {...register("message")}
                                            sx={{
                                                backgroundColor: "white",
                                                border: "none",
                                                borderRadius: "5px",
                                            }}
                                            multiline
                                            minRows={4}
                                            maxRows={12}
                                            placeholder={t("footer.messageField")}
                                        />
                                        <FormHelperText
                                            sx={{
                                                color: "#fdd73b !important",
                                                textAlign: alignment,
                                            }}
                                        >
                                            {errors?.message?.message}
                                        </FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        disabled={submitted}
                                        sx={Styles.joinUsBtn}
                                        type="submit"
                                    >
                                        {t("footer.sendBtn")}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default ContactForm;
