/** @jsxImportSource @emotion/react */
import { Box, Button, Container, Divider, Grid, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import i18next from "i18next";
import { Link, useNavigate } from "react-router-dom";
import LogoWhite from "assets/images/whiteLogo.svg";
import Facebook from "assets/icons/Facebook.svg";
import Twitter from "assets/icons/Twitter.svg";
import Instagram from "assets/icons/Instagram.svg";
import Phone from "assets/icons/Phone.svg";
import EmailSend from "assets/icons/EmailSend.svg";
import { useRecoilValue } from "recoil";
import parse from "html-react-parser";
import { footerState, staticContent } from "recoil/atoms";
import * as Styles from "./footer.styles";
import JoinUs from "./JoinUs";
import ContactForm from "./ContactForm";
import LastNews from "../lastNews/LastNews";

const Footer = ({ opened }) => {
    const { t, i18n } = useTranslation();
    const contact = useRecoilValue(footerState);
    const content = useRecoilValue(staticContent);
    const textAlign = i18next.language === "ar" ? "right" : "left";
    const navigate = useNavigate();

    const facebooklink = content?.find((i) => i.slug === "facebook-link");
    const instagramLink = content?.find((i) => i.slug === "instagram-link");
    const twitterLink = content?.find((i) => i.slug === "twitter-link");
    const phone = content?.find((i) => i.slug === "phone");
    const email = content?.find((i) => i.slug === "email");

    return (
        <Box
            sx={{
                filter: { xs: opened && "blur(.5rem)", lg: "none" },
            }}
        >
            {contact ? <ContactForm /> : <JoinUs />}

            <Box css={Styles.footerWrapper}>
                <Container sx={{}}>
                    <Grid
                        container
                        spacing={11}
                        sx={{ display: "flex" }}
                    >
                        {/* logo */}
                        <Grid
                            item
                            xs={12}
                            md={3}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: { xs: "center", lg: "unset" },
                                }}
                            >
                                <Box sx={{ display: "flex" }}>
                                    <img
                                        src={LogoWhite}
                                        alt="El Masry Logo"
                                        width={150}
                                        height={150}
                                    />
                                </Box>
                                <Box
                                    css={Styles.footerSocialWrapper}
                                    sx={{
                                        maxWidth: { lg: "150px" },
                                    }}
                                >
                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            parse(
                                                `${
                                                    facebooklink?.items[0]?.description[
                                                        i18n.language
                                                    ]
                                                }`
                                            )?.props?.children
                                        }
                                    >
                                        <Box
                                            css={Styles.footerSocialIcon}
                                            sx={{
                                                marginInline: { xs: "10px", lg: "0" },
                                            }}
                                        >
                                            <img
                                                src={Facebook}
                                                alt="El Masry Logo"
                                                width={30}
                                                height={30}
                                            />
                                        </Box>
                                    </a>

                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            parse(
                                                `${
                                                    instagramLink?.items[0]?.description[
                                                        i18n.language
                                                    ]
                                                }`
                                            )?.props?.children
                                        }
                                    >
                                        <Box
                                            css={Styles.footerSocialIcon}
                                            sx={{
                                                marginInline: { xs: "10px", lg: "0" },
                                            }}
                                        >
                                            <img
                                                src={Instagram}
                                                alt="El Masry Logo"
                                                width={30}
                                                height={30}
                                            />
                                        </Box>
                                    </a>

                                    <a
                                        target="_blank"
                                        rel="noreferrer"
                                        href={
                                            parse(
                                                `${
                                                    twitterLink?.items[0]?.description[
                                                        i18n.language
                                                    ]
                                                }`
                                            )?.props?.children
                                        }
                                    >
                                        <Box
                                            sx={{
                                                backgroundColor: "#ffffff69",
                                                display: "flex",
                                                justifyContent: " center",
                                                alignItems: "center",
                                                borderRadius: "50%",
                                                marginInline: { xs: "10px", lg: "0" },
                                                padding: "5px",
                                            }}
                                        >
                                            <img
                                                src={Twitter}
                                                alt="El Masry Logo"
                                                width={30}
                                                height={30}
                                            />
                                        </Box>
                                    </a>
                                </Box>
                                <Button
                                    sx={{
                                        color: "#010101",
                                        backgroundColor: "#FDD73B",
                                        borderRadius: "25px",
                                        paddingInline: "35px",
                                        fontSize: "16px",
                                        width: "190px",
                                        "&:hover": {
                                            backgroundColor: "#FDD73B",
                                        },
                                        position: "inherit",
                                    }}
                                    onClick={() => {
                                        navigate("/donations/methods");
                                    }}
                                >
                                    {t("footer.donate")}
                                </Button>
                            </Box>
                        </Grid>
                        {/* news */}
                        <Grid
                            item
                            xs={12}
                            md={3}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography
                                    variant="h4"
                                    sx={{
                                        color: "white",
                                        fontSize: "22px",
                                        marginBottom: "30px",
                                        fontWeight: "700",
                                        textAlign: { textAlign },
                                    }}
                                >
                                    {t("footer.lastNews")}
                                </Typography>
                                <LastNews
                                    limit={2}
                                    titleColor="white"
                                    dateColor="rgba(255, 255, 255, 0.6)"
                                />
                            </Box>
                        </Grid>
                        {/* quick links */}
                        <Grid
                            item
                            xs={12}
                            md={3}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography
                                    sx={{
                                        color: "white",
                                        fontSize: "22px",
                                        marginBottom: "30px",
                                        textAlign: { textAlign },
                                        fontWeight: "700",
                                    }}
                                >
                                    {t("footer.quickLinks")}
                                </Typography>
                                <Box sx={{ textAlign: { textAlign }, marginBlock: "10px" }}>
                                    <Link
                                        to="/"
                                        style={{
                                            textDecoration: "none",
                                            color: "white",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("footer.home")}
                                    </Link>
                                    <Divider
                                        variant="fullWidth"
                                        sx={{ backgroundColor: "#E6EEF6" }}
                                    />
                                </Box>
                                <Box sx={{ textAlign: { textAlign }, marginBlock: "10px" }}>
                                    <Link
                                        to="/aboutUs"
                                        style={{
                                            textDecoration: "none",
                                            color: "white",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("footer.about")}
                                    </Link>
                                    <Divider
                                        variant="fullWidth"
                                        sx={{ backgroundColor: "#E6EEF6" }}
                                    />
                                </Box>
                                <Box sx={{ textAlign: { textAlign }, marginBlock: "10px" }}>
                                    <Link
                                        to="/causes"
                                        style={{
                                            textDecoration: "none",
                                            color: "white",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("footer.cases")}
                                    </Link>
                                    <Divider
                                        variant="fullWidth"
                                        sx={{ backgroundColor: "#E6EEF6" }}
                                    />
                                </Box>
                                <Box sx={{ textAlign: { textAlign }, marginBlock: "10px" }}>
                                    <Link
                                        to="/news"
                                        style={{
                                            textDecoration: "none",
                                            color: "white",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("footer.blog")}
                                    </Link>
                                    <Divider
                                        variant="fullWidth"
                                        sx={{ backgroundColor: "#E6EEF6" }}
                                    />
                                </Box>
                                <Box sx={{ textAlign: { textAlign }, marginBlock: "10px" }}>
                                    <Link
                                        to="/contactUs"
                                        style={{
                                            textDecoration: "none",
                                            color: "white",
                                            fontSize: "18px",
                                            fontWeight: 600,
                                        }}
                                    >
                                        {t("footer.contact")}
                                    </Link>
                                    <Divider
                                        variant="fullWidth"
                                        sx={{ backgroundColor: "#E6EEF6" }}
                                    />
                                </Box>
                            </Box>
                        </Grid>
                        {/* Have a question */}
                        <Grid
                            item
                            xs={12}
                            md={3}
                        >
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                                <Typography
                                    sx={{
                                        color: "white",
                                        fontSize: "22px",
                                        marginBottom: "30px",
                                        textAlign: { textAlign },
                                        fontWeight: "700",
                                    }}
                                >
                                    {t("footer.haveQuestion")}
                                </Typography>
                                <Box sx={{ display: "flex", marginBlock: "10px" }}>
                                    <img
                                        src={EmailSend}
                                        alt="email icon"
                                    />
                                    <Typography sx={{ marginInline: "5px", color: "white" }}>
                                        {parse(`${email?.items[0]?.description[i18n.language]}`)}
                                    </Typography>
                                </Box>
                                <Box sx={{ display: "flex", marginBlock: "10px" }}>
                                    <img
                                        src={Phone}
                                        alt="email icon"
                                    />
                                    <Typography sx={{ marginInline: "10px", color: "white" }}>
                                        {parse(`${phone?.items[0]?.description[i18n.language]}`)}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </Box>
    );
};

Footer.propTypes = {
    opened: PropTypes.bool.isRequired,
};

export default Footer;
