import React from "react";
import Button from "@mui/material/Button";
import Proptypes from "prop-types";
import Loader from "../loaders/loader/Loader";

const CustomButton = ({
    text,
    isLoading,
    disabled,
    color,
    sx,
    onClick,
    type,
    onEnter,
    onLeave,
}) => (
    <Button
        onMouseEnter={onEnter}
        onMouseLeave={onLeave}
        sx={{
            background: color || "#DB6015",
            borderRadius: 1,
            badding: 20,
            "&:hover": { background: color || "#DB6015" },
            ...sx,
        }}
        onClick={onClick}
        disabled={disabled || isLoading}
        variant="contained"
        type={type}
    >
        {isLoading ? <Loader customSize={20} /> : text}
    </Button>
);

CustomButton.propTypes = {
    text: Proptypes.string.isRequired,
    isLoading: Proptypes.bool,
    disabled: Proptypes.bool,
    color: Proptypes.string,
    type: Proptypes.string.isRequired,
    onClick: Proptypes.func.isRequired,
    onEnter: Proptypes.func,
    onLeave: Proptypes.func,
    sx: Proptypes.any,
};

CustomButton.defaultValues = {
    isLoading: false,
    disabled: false,
    color: "#DB6015",
    sx: {},
    onEnter: "",
    onLeave: "",
};
export default CustomButton;
