/** @jsxImportSource @emotion/react */

import React from "react";
import { Avatar, Badge, Box, Button, Slide } from "@mui/material";
import { PropTypes } from "prop-types";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get, set } from "configs/localstorage.service";
import ShoppingCart from "assets/icons/cart.svg";
import Facebook from "assets/icons/Facebookheader.svg";
import Instagram from "assets/icons/instagramHeader.svg";
import Twitter from "assets/icons/twitterHeader.svg";
import Linkedin from "assets/icons/linkedin.svg";
import {
    DonationsMenu,
    NewsAndEventsMenu,
    ProfileMenu,
    // SectorsMenu,
    WhoAreWeMenu,
} from "helpers/constants";
import { useRecoilState, useRecoilValue } from "recoil";
import { cartItems, navbarOpened } from "recoil/atoms";
import {
    ButtonStyles,
    DropDown,
    LanguageNav,
    ResponsiveNavItems,
    activeLink,
} from "./navbar.styles";
import CustomDropDown from "../uikit/dropdown/dropDown";
import CustomButton from "../uikit/customButton/CustomButton";

const ResponsiveNavbar = ({ active, setActive }) => {
    const { t, i18n } = useTranslation();
    const [opened, setOpened] = useRecoilState(navbarOpened);
    const cartCount = useRecoilValue(cartItems);
    const navigate = useNavigate();
    const user = get("user");
    const black =
        location?.pathname.includes("login") ||
        location?.pathname.includes("registration") ||
        location?.pathname.includes("cart") ||
        location?.pathname.includes("404") ||
        location?.pathname.includes("profile");
    return (
        <Slide
            direction="up"
            in={opened}
        >
            <Box
                sx={{
                    minHeight: "100vh",
                    boxSizing: "border-box",
                    height: "100%",
                    zIndex: 99,
                    width: "100%",
                    display: { xs: "block", lg: "none" },
                    backgroundColor: "#000",
                    opacity: "0.9",
                    position: "fixed",
                    top: 0,
                    overflow: "visible !important",
                    right: 0,
                    paddingTop: 10,
                    left: 0,
                    bottom: 0,
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        paddingInline: { xs: 2, md: 10 },
                        justifyContent: "space-around",
                        color: "#000",
                        flexDirection: "column",
                        height: "100%",
                    }}
                >
                    <Link
                        css={[ResponsiveNavItems(), activeLink(active === "0")]}
                        onClick={() => {
                            setOpened((prev) => !prev);
                            setActive("0");
                            set("active", "0");
                        }}
                        to="/"
                    >
                        {t("navbar.home")}
                    </Link>

                    <CustomDropDown
                        isParent
                        label={t("navbar.who_are_we")}
                        items={WhoAreWeMenu}
                        setOpened={setOpened}
                        setActive={setActive}
                        active="1"
                        current={active}
                        sx={DropDown()}
                        transform={{
                            horizontal: i18n.language === "ar" ? "right" : "left",
                            vertical: "right",
                        }}
                        anchor={{
                            horizontal: i18n.language === "ar" ? "right" : "left",
                            vertical: "bottom",
                        }}
                    />
                    <Link
                        css={[ResponsiveNavItems(), activeLink(active === "5")]}
                        onClick={() => {
                            setOpened((prev) => !prev);
                            setActive("5");
                            set("active", "5");
                        }}
                        to="/oursectors"
                    >
                        {t("navbar.our_sectors")}
                    </Link>

                    <CustomDropDown
                        isParent
                        label={t("navbar.news_and_events")}
                        setOpened={setOpened}
                        items={NewsAndEventsMenu}
                        setActive={setActive}
                        active="3"
                        current={active}
                        sx={DropDown()}
                        transform={{
                            horizontal: i18n.language === "ar" ? "right" : "left",
                            vertical: "top",
                        }}
                        anchor={{
                            horizontal: i18n.language === "ar" ? "right" : "left",
                            vertical: "bottom",
                        }}
                    />
                    <Link
                        css={[ResponsiveNavItems(), activeLink(active === "2")]}
                        onClick={() => {
                            setOpened((prev) => !prev);
                            setActive("2");
                            set("active", "2");
                        }}
                        to="/research"
                    >
                        {t("navbar.research_and_reports_hub")}
                    </Link>
                    <Link
                        css={[ResponsiveNavItems(), activeLink(active === "4")]}
                        onClick={() => {
                            setOpened((prev) => !prev);
                            setActive("4");
                            set("active", "4");
                        }}
                        to="/zkaty"
                    >
                        {t("navbar.zakaty")}
                    </Link>
                    <Link
                        css={[ResponsiveNavItems(), activeLink(active === "6")]}
                        onClick={() => {
                            setActive("6");
                            set("active", "6");
                        }}
                        to="/joinUs"
                        style={{ textDecoration: "none" }}
                    >
                        {t("navbar.joinUs")}
                    </Link>
                    <CustomDropDown
                        isParent
                        label={t("navbar.donations")}
                        items={DonationsMenu}
                        setOpened={setOpened}
                        setActive={setActive}
                        active="7"
                        current={active}
                        sx={DropDown()}
                        transform={{
                            horizontal: i18n.language === "ar" ? "right" : "left",
                            vertical: "top",
                        }}
                        anchor={{
                            horizontal: i18n.language === "ar" ? "right" : "left",
                            vertical: "bottom",
                        }}
                    />
                    {user ? (
                        <CustomDropDown
                            label={
                                <Avatar
                                    src={user?.avatar}
                                    sx={LanguageNav(false)}
                                />
                            }
                            items={ProfileMenu}
                            setOpened={setOpened}
                            current={active}
                            sx={DropDown()}
                            transform={{
                                horizontal: "center",
                                vertical: "top",
                            }}
                            anchor={{
                                horizontal: "center",
                                vertical: "bottom",
                            }}
                        />
                    ) : (
                        <React.Fragment>
                            <Button
                                onClick={() => {
                                    setOpened((prev) => !prev);
                                    navigate("/login");
                                }}
                                sx={ButtonStyles(true, false, false)}
                            >
                                {t("navbar.login")}
                            </Button>
                            <Button
                                onClick={() => {
                                    setOpened((prev) => !prev);
                                    navigate("/registration");
                                }}
                                sx={ButtonStyles(false, false, false)}
                            >
                                {t("navbar.register")}
                            </Button>
                        </React.Fragment>
                    )}
                    <Box sx={{ display: "flex", justifyContent: "space-evenly", width: "50%" }}>
                        <Box>
                            <Badge
                                badgeContent={cartCount?.length}
                                color="warning"
                                onClick={() => {
                                    setOpened((prev) => !prev);

                                    navigate("/cart");
                                }}
                            >
                                <Avatar
                                    src={ShoppingCart}
                                    sx={{
                                        color: "#fff",
                                        borderRadius: black ? "50%" : 1,
                                        border: black && "2px solid #DB6015",
                                        width: 32,
                                        height: 32,
                                        cursor: "pointer",
                                    }}
                                />
                            </Badge>
                        </Box>
                        <Box>
                            <Avatar
                                src={
                                    i18n.language === "en"
                                        ? "https://flagcdn.com/w20/eg.png"
                                        : "https://flagcdn.com/w20/gb.png"
                                }
                                sx={LanguageNav(false, false)}
                                onClick={() => {
                                    i18n.changeLanguage(i18n.language === "ar" ? "en" : "ar").then(
                                        () => {
                                            set("lang", i18n.language);
                                        }
                                    );
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            position: { xs: !opened ? "fixed" : "none", md: "fixed" },
                            display: "flex",
                            bottom: 5,
                            left: { xs: 5, md: 50 },
                            alignItems: "end",
                            justifyContent: "space-around",
                            gap: { xs: 1, md: 2 },
                            paddingBottom: 3,
                            zIndex: 99999,
                            overflow: "visible",
                        }}
                    >
                        <CustomButton
                            sx={{
                                backgroundColor: "rgba(66, 103, 178,1.0)",
                                borderRadius: "50%",
                                width: 50,
                                height: 60,
                                transition: "ease-in-out .2s",
                                border: "solid 1px #fff",
                                transform: "scale(.8,.8)",
                                "&:hover": {
                                    backgroundColor: "rgba(66, 103, 178,1.0)",
                                    transform: "scale(.83,.83)",
                                },
                            }}
                            text={
                                <img
                                    src={Facebook}
                                    alt="facebook"
                                />
                            }
                        />
                        <CustomButton
                            sx={{
                                background:
                                    " linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",

                                borderRadius: "50%",
                                width: 50,
                                height: 60,
                                transition: "ease-in-out .2s",
                                border: "solid 1px #fff",
                                transform: "scale(.8,.8)",
                                "&:hover": {
                                    background:
                                        " linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%)",
                                    transform: "scale(.83,.83)",
                                },
                            }}
                            text={
                                <img
                                    src={Instagram}
                                    alt="Instagram"
                                />
                            }
                        />
                        <CustomButton
                            sx={{
                                backgroundColor: "#00acee",
                                borderRadius: "50%",
                                width: 50,
                                height: 60,
                                transition: "ease-in-out .2s",
                                border: "solid 1px #fff",
                                transform: "scale(.8,.8)",
                                "&:hover": {
                                    background: "#00acee",
                                    transform: "scale(.83,.83)",
                                },
                            }}
                            text={
                                <img
                                    src={Twitter}
                                    alt="Twitter"
                                />
                            }
                        />
                        <CustomButton
                            sx={{
                                backgroundColor: "RGB(10, 102, 194)",
                                borderRadius: "50%",
                                width: 50,
                                height: 60,
                                transition: "ease-in-out .2s",
                                border: "solid 1px #fff",
                                transform: "scale(.8,.8)",
                                "&:hover": {
                                    background: "RGB(10, 102, 194)",
                                    transform: "scale(.83,.83)",
                                },
                            }}
                            text={
                                <img
                                    src={Linkedin}
                                    alt="Linkedin"
                                />
                            }
                        />
                    </Box>
                </Box>
            </Box>
        </Slide>
    );
};

ResponsiveNavbar.propTypes = {
    active: PropTypes.bool.isRequired,
    setActive: PropTypes.func.isRequired,
};
export default ResponsiveNavbar;
