import AxiosInstance from "configs/api.service";

export const sendMail = (data) =>
    new Promise((resolve, reject) => {
        AxiosInstance.post("/api/contactus", data)
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });

export const getCountries = () => new Promise((resolve, reject) => {
        AxiosInstance.get("/api/countries")
            .then((res) => resolve(res.data))
            .catch((err) => reject(err));
    });
export const getSingleCountry = (id) => new Promise((resolve, reject) => {
        AxiosInstance.get(`/api/countries/${id}`)
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => reject(err));
    });
